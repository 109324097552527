<template>
    <!-- 账号资料 移动端 -->
    <div class="center">
        <div class="row" 
        @click="themeChange('dark')">
            <!-- <i class="iconfont icon-moon"></i> -->
            <div class="left">
                {{$t('user.dark')}}
            </div>
            <div class="right check" v-show="theme=='dark'">
				<i class="iconfont2 icon-yes"></i>
            </div>
        </div>
        <div class="row" 
        @click="themeChange('light')">
			<!-- <i class="iconfont2 icon-rijian"></i> -->
            <div class="left">
                {{$t('user.light')}}
            </div>
            <div class="right check" v-show="theme=='light'">
				<i class="iconfont2 icon-yes"></i>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
	@import 'center.styl';
</style>
<script>
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';

export default {
    data(){
        return{
        }
    },
    created(){
        if(this.isMobile==1){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });

            loading.close();
        }else{
            this.$router.push({
                path:'/user'
            })
        }
    },
    computed:{
        ...mapGetters(['theme','isMobile']),
    },
    methods:{
        themeChange(theme){//theme
            // var theme=this.theme=='dark'? 'light':'dark'
            if(this.theme!=theme){
                this.$store.dispatch('app/steTheme',theme)
            }
        },
    },
}
</script>
